<template>
  <section class="user-listing">
    <pageLoader v-if="ploader" :loadingText="'En cours de chargement'"/>
    <div class="row">
      <div class="col-md-12">
        <!-- <button class="btn" @click="getCookie">get cookies</button> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="d-flex mb-4">
                  <h5 class="mr-2 font-weight-semibold border-right pr-2 mr-2">Utilisateurs</h5>
                  <h5 class="font-weight-semibold">{{usersNumber}}</h5>
                </div>
              </div>
              <div class="col-md-5 offset-md-2">
                <b-input-group>
                  <router-link to='/utilisateur/ajouter'>
                <button type="button" class="btn btn btn-gradient-success btn-fw btn-secondary">Ajouter</button>
                  </router-link>
                      <b-form-input placeholder="Recherche" v-model="search" @keyup="findUser()"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <template v-if="user_display">
              <div class="row mx-sm-0" v-for="(users,u) in paginatedCards" :key="u">
              <div class="col-md-12 mb-5 pt-2 border px-0">
                <div class="card rounded shadow-none">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6 col-lg-5 d-lg-flex">
                        <div class="user-avatar mb-auto position-relative">
                          <div class="user-status">
                            <div class="actif-status" v-if="users.isactive" title="Le compte de l'utilisateur est activé"></div>
                            <div class="inactif-status" v-else title="Le compte de l'utilisateur est inactif"></div>
                          </div>
                          <span v-if="users.image">
                            <!-- <p>{{users.image}}</p> -->
                            <picture>
                              <source class="profile-img img-lg rounded-circle"  :srcset="users.image.content.webpImage" type="image/webp">
                              <img id="profil" class="profile-img img-lg rounded-circle" :src="users.image.content.originalImage" alt="image" />
                            </picture>
                          </span>
                          <img v-else src="@/assets/images/import/default-user.png" alt="profile image" class="profile-img img-lg rounded-circle">

                          <!-- <span class="edit-avatar-icon"><i class="mdi mdi-camera"></i></span> -->
                        </div>
                        <div class="wrapper pl-lg-4">
                          <div class="wrapper d-flex align-items-center">
                            <h4 class="mb-0 font-weight-medium">{{users.firstname}} {{users.lastname}}</h4>
                            <div class="badge badge-secondary text-light mt-2 ml-2">{{users.pseudo}}</div>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="mdi mdi-email-outline mr-2"></i>
                            <p class="mb-0 text-muted">{{users.email}}</p>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="mdi mdi-phone mr-2"></i>
                            <p class="mb-0 text-muted">{{users.tel}}</p>
                          </div>

                          <!-- affichage des rôles -->
                          <div v-for="role in users.roles" :key=role>
                              <span v-if="role==='ROLE_ADMIN_DASHBOARD'" class="badge badge-outline-primary mt-1">Administrateur du dashboard</span>
                              <span v-else-if="role==='ROLE_USER_DASHBOARD'" class="badge badge-outline-primary mt-1">Administrateur du site</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-3">
                        <div class="py-4" v-if="users.mainaddress">
                          <p class="clearfix">
                            <span class="float-left"> Rue 1 </span>
                            <span class="float-right text-muted"> {{users.mainaddress.street1}}</span>
                          </p>
                          <p class="clearfix">
                            <span class="float-left"> Rue 2 </span>
                            <span class="float-right text-muted"> {{users.mainaddress.street2}}</span>
                          </p>
                          <p class="clearfix">
                            <span class="float-left"> Ville </span>
                            <span class="float-right text-muted"> {{users.mainaddress.city}} </span>
                          </p>
                          <p class="clearfix">
                            <span class="float-left"> Département </span>
                            <span class="float-right text-muted"> {{users.mainaddress.state}} </span>
                          </p>
                          <p class="clearfix">
                            <span class="float-left"> Pays </span>
                            <span class="float-right text-muted"> {{users.mainaddress.country}} </span>
                          </p>
                          <p class="clearfix">
                            <span class="float-left"> Boite postal </span>
                            <span class="float-right text-muted"> {{users.mainaddress.postal_code}} </span>
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-4">
                        <div class="wrapper d-flex float-right">
                          <button type="button" class="btn btn-sm btn-gradient-primary mr-2" @click="showAlert(users)"><i class="mdi mdi-plus"></i></button>
                          <button type="button" class="btn btn-sm btn-gradient-info" @click="editUser(users)"><i class="mdi mdi-pen"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              
            </div>

            <b-pagination class="rounded-separated d-flex justify-content-center computer" v-model="currentPage" :per-page="perPage" :total-rows="user_display.length"></b-pagination>
          
            </template>
            <!-- <div class="row mx-sm-0" > -->
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/vue-cookies@1.7.4/vue-cookies.js"></script>

<script>
import Snotify, { SnotifyPosition} from 'vue-snotify'
import pageLoader from '../../../components/pageLoader.vue'
const axios= require ('axios').default
export default {
  name: 'disabled_users',
  components:{
    pageLoader
  },
  data () {
    return {
      users:[],
      ploader:false,
      user_display:[],
      currentPage:1,
      perPage: 5,
      search:''
    }
  },
  computed: {
    filter(){
      return this.users.filter((users) => {
        return users.firstname.match (this.search);
      })
    },
    usersNumber() {
      return this.users.length
    },
      paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.user_display.slice(start, end);
      }
  },
  methods:{
     getUsers() {
      this.user_display = null
      axios.get('api/users')
      .then(resGetUser=> {
        this.users=resGetUser.data.result.filter(users => !users.isactive)
        this.user_display = this.users
        this.ploader=false
        })
      .catch(errGetUser=> console.log(errGetUser))
      },
      //delete user
      deleteUser(users) {
        axios.put(`api/users/${users.ulid}`,{
          isactive:true
        })
        .then(() =>
          this.$swal(
            'Activé!',
            'Le compte de l\'utilisateur a bien été activé',
            'success'
          ),
          this.$router.push({
            name: "user"
          })
          // this.getUsers()
      )
        .catch(err=>{
          if(err.response.data=="Delete failed."){
            alert('Erreur de suppression!'+
            'Cet utilisateur a encore des réservations ou achat en cours')
          }
          console.log({err})
          })
      },

      //edit user
      editUser(user) {
        // console.log(user)
        this.$router.push (
          {
            name:'editUser',
            params: {
              ulid: user.ulid
            }
          }
        )
      },
      showSnotify ()  {
        this.$snotify.success('test', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          
        })
      },
      showAlert (users) {
        this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous réactiver cet utilisateur?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Réactiver',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
          this.deleteUser(users)
          
        }
      })
    },
    addImage(ulid) {
      axios.put(`api/users/${ulid}`,{
        'image':ulid
      })
      .then(resAddImage => console.log(resAddImage))
      .catch(errAddImage => console.log(errAddImage))
    },
    findUser()
    {
        this.user_display=null
        this.currentPage = 1
        const regex = new RegExp(this.search.toLowerCase());
        let newuser = []
        // if (this.filtrekey == "") {
        // // newproduct = this.products
        // }
        this.users.forEach(element => {
            if(regex.test(element.firstname.toLowerCase()) ){
                newuser.push(element)
            }
        })
        this.user_display = newuser
    },
  },
   created() {
    this.getUsers()
  },
}
</script>
<style scoped>
.user-status{
  position: absolute;
  top: 10;
  right:11px
}
.actif-status{
  width: 15px;
  height: 15px;
  background: rgb(8, 213, 8);
  border-radius:100%;
  display: block;
}
.inactif-status{
  width: 15px;
  height: 15px;
  background: rgb(213, 80, 8);
  border-radius:100%;
  display: block;
}
</style>